import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotVerifyFactor4X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
        fill="#8FDEFF"
      />
      <path d="M12 23.25c6.213 0 11.25-5.037 11.25-11.25S18.213.75 12 .75.75 5.787.75 12 5.787 23.25 12 23.25Z" />
      <path
        d="m7.518 9.66 1.048 1.928L9.645 9.66h1.798l-1.793 3.1L11.525 16H9.721l-1.143-2.04L7.436 16H5.63l1.875-3.24-1.793-3.1h1.805Zm10.652 3.152v1.325h-6.147l-.082-1.026 3.557-5.642h1.342L15.387 9.9l-1.77 2.912h4.553Zm-.967-5.343V16h-1.687V7.469h1.687Z"
        fill="#005071"
      />
    </svg>
  );
};
export default SvgNotVerifyFactor4X;
