import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotVerifyFactor3X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
        fill="#F2CD2C"
      />
      <path d="M12 23.25c6.213 0 11.25-5.037 11.25-11.25S18.213.75 12 .75.75 5.787.75 12 5.787 23.25 12 23.25Z" />
      <path
        d="m7.518 9.66 1.048 1.928L9.645 9.66h1.798l-1.793 3.1L11.525 16H9.721l-1.143-2.04L7.436 16H5.63l1.875-3.24-1.793-3.1h1.805Zm6.404 1.342h.902c.29 0 .528-.049.715-.147a.964.964 0 0 0 .416-.421c.094-.184.14-.397.14-.64 0-.218-.042-.411-.128-.58a.874.874 0 0 0-.381-.398c-.172-.097-.389-.146-.65-.146a1.34 1.34 0 0 0-.575.123 1.05 1.05 0 0 0-.421.346.906.906 0 0 0-.159.539h-1.693c0-.469.125-.877.375-1.225a2.55 2.55 0 0 1 1.02-.814 3.333 3.333 0 0 1 1.406-.293c.578 0 1.084.093 1.517.281a2.24 2.24 0 0 1 1.014.814c.242.36.363.805.363 1.336 0 .27-.062.532-.187.786-.125.25-.305.476-.54.68-.23.198-.511.359-.843.48a3.35 3.35 0 0 1-1.12.175h-1.171v-.896Zm0 1.283v-.873h1.172c.465 0 .873.053 1.224.158.352.106.647.258.885.457.238.196.418.428.54.698.12.265.18.56.18.884 0 .399-.075.754-.228 1.067-.152.308-.367.57-.644.785a2.942 2.942 0 0 1-.961.492 4.19 4.19 0 0 1-1.201.164c-.36 0-.713-.049-1.06-.146a3.156 3.156 0 0 1-.938-.451 2.32 2.32 0 0 1-.668-.762c-.164-.309-.246-.674-.246-1.096h1.693c0 .219.055.414.164.586.11.172.26.307.451.404.195.098.412.147.65.147.27 0 .5-.049.692-.147.195-.101.344-.242.445-.421a1.26 1.26 0 0 0 .159-.64c0-.312-.057-.562-.17-.75a1.008 1.008 0 0 0-.487-.421c-.21-.09-.46-.135-.75-.135h-.902Z"
        fill="#544400"
      />
    </svg>
  );
};
export default SvgNotVerifyFactor3X;
