import * as React from 'react';
import { SVGProps } from 'react';

const SvgSilverStatus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M33.124.381c-2.816 2.79-12.14 11.16-24.435 13.448-1.065.208-1.34.398-1.494 1.525-.858 6.48-2.919 35.663 25.706 52.369.704.364 1.425.364 2.164 0 16.124-9.41 22.512-22.77 24.864-33.81L34 0c-.292 0-.584.121-.876.381Z"
          fill="#E1EBF0"
        />
        <path
          d="M7.315 29.702a45.5 45.5 0 0 0 .738 4.211h51.893l-3.21-4.21H7.314Z"
          fill="#87A0AE"
        />
        <path
          d="M34 33.913h25.947c1.82-8.56 1.219-15.735.84-18.577-.154-1.109-.446-1.317-1.476-1.507C47.033 11.559 37.691 3.17 34.876.38 34.584.121 34.292 0 34 0v33.913Z"
          fill="#F88360"
        />
        <path
          d="M46.951 11.838a.3.3 0 0 0-.416.003L42.1 16.238a.274.274 0 0 0-.082.195v6.376c0 .059.02.117.057.166a.3.3 0 0 0 .417.053l8.717-6.666a.272.272 0 0 0 .024-.418l-4.282-4.106Zm-.818-.43a.867.867 0 0 1 1.213-.01l4.282 4.107a.885.885 0 0 1-.074 1.338l-8.717 6.667a.868.868 0 0 1-1.216-.161.885.885 0 0 1-.184-.54v-6.376c0-.238.096-.463.261-.627l4.435-4.397Zm5.296 7.035a.3.3 0 0 0-.416-.062l-2.8 2.046a.273.273 0 0 0 0 .446l2.8 2.046a.3.3 0 0 0 .177.057.287.287 0 0 0 .292-.28v-4.091a.273.273 0 0 0-.053-.162Zm-.75-.55a.868.868 0 0 1 1.212.187c.11.151.172.335.172.525v4.09c0 .497-.401.88-.873.88a.868.868 0 0 1-.512-.167l-2.8-2.046a.885.885 0 0 1 0-1.424l2.8-2.046Zm-3.68 3.868a.302.302 0 0 0-.352 0l-1.853 1.34a.273.273 0 0 0-.083.353l1.853 3.42a.298.298 0 0 0 .518 0l1.853-3.42a.273.273 0 0 0-.084-.353L47 21.761Zm-.685-.49a.867.867 0 0 1 1.017 0l1.853 1.34c.355.256.47.742.257 1.136l-1.852 3.42a.869.869 0 0 1-1.532 0l-1.853-3.42a.884.884 0 0 1 .258-1.137l1.852-1.34Z"
          fill="#FFF4F1"
        />
        <path
          d="M34 33.913h25.947c.309-1.438.549-2.842.738-4.21H34v4.21Z"
          fill="#B45032"
        />
        <path
          d="M8.053 33.913c2.353 11.039 8.74 24.4 24.865 33.81.36.173.721.277 1.082.277V33.913H8.053Z"
          fill="#F88360"
        />
        <path
          d="M25.17 37.869a.3.3 0 0 0-.416.003l-4.435 4.397a.275.275 0 0 0-.082.195v6.376c0 .06.019.118.057.167a.3.3 0 0 0 .417.053l8.716-6.667a.272.272 0 0 0 .024-.418L25.17 37.87Zm-.818-.43a.867.867 0 0 1 1.213-.01l4.282 4.107a.885.885 0 0 1-.074 1.338l-8.717 6.667a.868.868 0 0 1-1.216-.161.884.884 0 0 1-.184-.54v-6.376c0-.237.095-.463.261-.627l4.435-4.397Zm5.295 7.035a.3.3 0 0 0-.415-.061l-2.8 2.045a.273.273 0 0 0 0 .446l2.8 2.046a.3.3 0 0 0 .177.057.287.287 0 0 0 .292-.28v-4.091a.272.272 0 0 0-.054-.162Zm-.75-.55a.868.868 0 0 1 1.212.187.887.887 0 0 1 .172.525v4.091c0 .496-.4.88-.872.88a.867.867 0 0 1-.512-.168l-2.8-2.045a.884.884 0 0 1 0-1.425l2.8-2.046Zm-3.68 3.868a.301.301 0 0 0-.351 0l-1.853 1.34a.273.273 0 0 0-.083.353l1.852 3.42a.298.298 0 0 0 .518 0l1.853-3.42a.273.273 0 0 0-.083-.353l-1.853-1.34Zm-.684-.49a.867.867 0 0 1 1.017 0l1.853 1.34c.355.256.47.743.257 1.136l-1.853 3.42a.869.869 0 0 1-1.531 0l-1.853-3.42a.884.884 0 0 1 .257-1.137l1.853-1.34Z"
          fill="#FFF4F1"
        />
        <path
          d="M32.9 63.512C18.667 55.61 11.678 44.917 8.329 35.162c2.559 10.795 9.067 23.515 24.59 32.56.36.174.721.278 1.082.278v-4.21a2.3 2.3 0 0 1-1.1-.278Z"
          fill="#B45032"
        />
        <path
          d="M59.534 35.733c-3.417 9.6-10.423 20.033-24.366 27.78-.395.19-.79.276-1.168.276v4.212c.36 0 .721-.087 1.082-.278 15.248-8.89 21.79-21.332 24.452-31.99Z"
          fill="#87A0AE"
        />
      </g>
    </svg>
  );
};
export default SvgSilverStatus;
