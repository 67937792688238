import * as React from 'react';
import { SVGProps } from 'react';

const SvgPlatinumStatus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M20.744 20.775c0 4.947-2.494 9.294-6.255 11.774-.6.395-1.103.34-1.607 0-3.734-2.535-6.255-6.84-6.255-11.774 0-4.946 2.575-9.157 6.255-11.773.558-.395.94-.463 1.607 0 3.72 2.562 6.255 6.827 6.255 11.773Zm26.789 0c0 4.947 2.494 9.294 6.255 11.774.6.395 1.117.34 1.621 0 3.734-2.535 6.255-6.84 6.255-11.774 0-4.946-2.576-9.157-6.255-11.773-.558-.395-.94-.463-1.621 0-3.72 2.562-6.255 6.827-6.255 11.773Z"
          fill="#78C2A4"
        />
        <path
          d="M52.003 30.954c-2.466 4.279-2.494 9.293-.477 13.327.313.64.79.858 1.39.804 4.497-.327 8.83-2.794 11.31-7.072 2.466-4.279 2.344-9.225.477-13.327-.286-.627-.586-.872-1.39-.804-4.497.368-8.844 2.793-11.31 7.072Zm-35.728 0c2.466 4.279 2.493 9.293.477 13.327-.328.64-.79.858-1.39.804-4.51-.327-8.844-2.794-11.31-7.072-2.467-4.279-2.344-9.225-.464-13.327.287-.627.586-.872 1.39-.804 4.497.368 8.83 2.793 11.297 7.072Z"
          fill="#95D6A4"
        />
        <path
          d="M50.177 36.432a13.836 13.836 0 0 0 1.362 7.85c.314.64.79.858 1.39.803 4.497-.327 8.83-2.793 11.31-7.072 1.594-2.752 2.112-5.764 1.772-8.666a13.81 13.81 0 0 1-1.772 5.355c-2.466 4.279-6.8 6.745-11.31 7.072-.613.04-1.076-.164-1.39-.804a13.561 13.561 0 0 1-1.362-4.538Zm-32.063 0a13.835 13.835 0 0 1-1.363 7.85c-.327.64-.79.858-1.39.803-4.51-.327-8.843-2.793-11.31-7.072-1.58-2.752-2.098-5.764-1.771-8.666a13.81 13.81 0 0 0 1.771 5.355c2.467 4.279 6.8 6.745 11.31 7.072.6.04 1.077-.164 1.39-.804a13.88 13.88 0 0 0 1.363-4.538Z"
          fill="#78C2A4"
        />
        <path
          d="M53.856 39.484c-4.279 2.467-6.813 6.8-7.072 11.31-.041.709.259 1.132.804 1.404 4.06 1.962 9.048 2.003 13.326-.477 4.279-2.466 6.65-6.813 7.073-11.31.068-.695-.069-1.05-.804-1.403-4.075-1.935-9.048-2.004-13.327.476Zm-39.435 0c4.279 2.467 6.8 6.8 7.072 11.31.041.709-.259 1.132-.804 1.404-4.06 1.949-9.048 1.99-13.326-.49C3.083 49.24.726 44.893.29 40.397c-.068-.695.069-1.05.804-1.404 4.075-1.921 9.048-1.99 13.327.49Z"
          fill="#B3E59F"
        />
        <path
          d="M47.111 48.533a15.398 15.398 0 0 0-.327 2.248c-.041.709.259 1.13.804 1.403 4.06 1.963 9.048 2.004 13.326-.476 4.28-2.467 6.65-6.814 7.073-11.31.054-.518-.014-.845-.368-1.131-.886 3.679-3.12 7.058-6.705 9.13-4.278 2.466-9.265 2.438-13.326.476a1.583 1.583 0 0 1-.477-.34Zm-25.931 0c.163.735.272 1.485.327 2.248.04.709-.259 1.13-.804 1.403-4.074 1.963-9.062 2.004-13.34-.476C3.084 49.24.727 44.894.29 40.398c-.055-.518.013-.845.368-1.131.885 3.679 3.12 7.058 6.704 9.13 4.278 2.466 9.266 2.438 13.326.463.191-.082.355-.191.49-.327Z"
          fill="#95D6A4"
        />
        <path
          d="M50.735 47.933c-4.946 0-9.293 2.493-11.773 6.254-.395.6-.34 1.104 0 1.608 2.535 3.734 6.84 6.255 11.773 6.255 4.947 0 9.157-2.576 11.774-6.255.395-.559.463-.94 0-1.608-2.562-3.706-6.84-6.254-11.774-6.254Zm-33.194 0c4.947 0 9.294 2.493 11.774 6.254.395.6.34 1.104 0 1.608-2.535 3.734-6.827 6.255-11.774 6.255-4.946 0-9.157-2.576-11.773-6.255-.395-.559-.463-.94 0-1.608 2.562-3.706 6.84-6.254 11.773-6.254Z"
          fill="#CCF49F"
        />
        <path
          d="M39.562 53.33a9.77 9.77 0 0 0-.613.858c-.396.6-.341 1.103 0 1.607 2.534 3.734 6.84 6.255 11.773 6.255 4.946 0 9.157-2.575 11.773-6.255.395-.558.463-.94 0-1.607a10.13 10.13 0 0 0-.64-.859c-2.644 3.23-6.582 5.423-11.147 5.423S42.151 56.6 39.562 53.33Zm-10.847 0c.218.272.423.558.613.858.396.6.341 1.103 0 1.607-2.534 3.734-6.826 6.255-11.773 6.255-4.946 0-9.157-2.575-11.773-6.255-.395-.558-.463-.94 0-1.607.204-.3.422-.586.64-.859 2.644 3.23 6.582 5.423 11.133 5.423 4.565 0 8.571-2.153 11.16-5.423Z"
          fill="#B3E59F"
        />
        <path
          d="M28.42 3.4c-.729-.013-1.248.379-1.584 1.05-3.601 7.07-8.997 11.451-16.9 12.949-.772.14-1.206.588-1.22 1.386-.154 5.893.56 32.251 24.707 45.564.63.35 1.345.35 1.976 0 24.16-13.313 24.875-39.671 24.72-45.564-.027-.798-.448-1.246-1.219-1.386C50.996 15.9 45.601 11.519 42 4.45c-.337-.658-.855-1.064-1.584-1.05H28.42Z"
          fill="#E5E4E2"
        />
        <path
          d="M8.744 18.533c-.014.084-.014.154-.028.252-.154 5.893.56 32.252 24.707 45.564.63.35 1.345.35 1.976 0 24.16-13.312 24.875-39.67 24.72-45.564a1.54 1.54 0 0 0-.027-.252C59.769 27.59 56.7 49.47 35.399 61.2a1.99 1.99 0 0 1-1.976 0C12.135 49.47 9.066 27.59 8.744 18.533Z"
          fill="#D6D5D5"
        />
        <path
          d="M16.213 23.335c.337 4.437 1.29 8.986 2.72 13.004 2.886 8.175 7.805 14.81 14.826 19.583.448.294.897.28 1.345 0 7.021-4.773 11.94-11.394 14.827-19.583 1.416-4.018 2.383-8.567 2.719-13.004-6.054-3.332-12.108-4.984-18.218-4.956-6.04.014-12.137 1.666-18.219 4.956Z"
          fill="#8FDEFF"
        />
        <path
          d="M16.213 23.334c.337 4.437 1.29 8.987 2.72 13.004 2.886 8.175 7.805 14.81 14.826 19.584.448.294.897.28 1.345 0 7.021-4.774 11.94-11.395 14.827-19.584 1.416-4.017 2.383-8.567 2.719-13.004a6.131 6.131 0 0 1-.308-.168c-.477 3.458-1.29 6.901-2.397 10.023-2.887 8.175-7.806 14.81-14.827 19.583-.448.28-.897.294-1.345 0C26.752 48 21.833 41.378 18.946 33.19c-1.107-3.122-1.934-6.565-2.396-10.023a3.758 3.758 0 0 0-.337.168Z"
          fill="#4490B0"
        />
        <path
          d="M34.418 46.193c-10.034 0-18.863.966-27.426 2.716-.434.084-.77-.07-.757-.63l.112-5.151A2.06 2.06 0 0 0 5.9 41.77l-3.21-4.2c-.294-.391-.111-.727.295-.825 9.824-2.352 19.998-3.668 31.434-3.668 11.45 0 21.61 1.316 31.433 3.682.407.098.589.434.294.826l-3.209 4.199a2.14 2.14 0 0 0-.448 1.358l.112 5.151c.014.56-.322.714-.757.63-8.548-1.75-17.377-2.73-27.425-2.73Z"
          fill="#F88360"
        />
        <path
          d="M34.418 46.193c-10.034 0-18.863.966-27.426 2.716-.434.084-.77-.07-.757-.63l.056-2.772c.112.266.379.336.701.266 8.563-1.75 17.392-2.715 27.426-2.715 10.048 0 18.863.965 27.425 2.715.322.07.589 0 .7-.266l.057 2.772c.014.56-.322.714-.757.63-8.549-1.736-17.377-2.716-27.425-2.716Z"
          fill="#B45032"
        />
        <path
          d="m50.94 18.925 1.584 2.94c.098.196.266.308.49.35l3.293.587c.225.042.253.14.099.308l-2.313 2.422a.62.62 0 0 0-.182.574l.449 3.318c.028.21-.056.28-.253.182l-3.013-1.456a.655.655 0 0 0-.602 0l-3.013 1.456c-.21.097-.28.028-.253-.182l.449-3.318a.685.685 0 0 0-.182-.574l-2.34-2.408c-.14-.154-.14-.252.097-.308l3.294-.587a.681.681 0 0 0 .49-.35l1.584-2.94c.098-.182.224-.182.322-.014Zm-33.031 0-1.584 2.94c-.098.196-.266.308-.49.35l-3.293.587c-.225.042-.253.14-.098.308l2.298 2.436a.62.62 0 0 1 .182.574l-.434 3.318c-.029.21.056.28.252.182l3.013-1.456a.655.655 0 0 1 .602 0l3.027 1.456c.21.098.28.028.253-.182l-.449-3.318c-.042-.238.042-.42.182-.574l2.313-2.422c.14-.154.14-.252-.098-.308l-3.294-.587a.71.71 0 0 1-.49-.35l-1.584-2.94c-.084-.182-.21-.182-.308-.014ZM34.123 8.776 31.25 14.11c-.182.35-.476.56-.883.644l-5.956 1.077c-.392.07-.448.266-.182.546l4.19 4.382c.28.294.393.63.337 1.036l-.813 6.005c-.056.378.112.504.462.336l5.466-2.632a1.248 1.248 0 0 1 1.093 0l5.466 2.632c.378.182.518.056.462-.336l-.813-6.005a1.18 1.18 0 0 1 .337-1.036l4.176-4.382c.266-.28.266-.462-.182-.546l-5.956-1.077a1.177 1.177 0 0 1-.883-.644l-2.873-5.334c-.154-.308-.393-.322-.575 0Z"
          fill="#F88360"
        />
        <path
          d="m53.94 26.4.406 3.024c.028.21-.056.28-.253.182L51.08 28.15a.654.654 0 0 0-.602 0l-3.013 1.456c-.21.097-.28.028-.253-.182l.407-3.024 2.859-1.372a.654.654 0 0 1 .602 0l2.86 1.372Zm-39.044 0-.406 3.038c-.028.21.056.28.252.182l3.013-1.456a.655.655 0 0 1 .603 0l3.027 1.456c.21.098.28.028.252-.182l-.407-3.024-2.872-1.372a.654.654 0 0 0-.603 0L14.896 26.4Zm13.425-1.386-.378 2.772c-.056.378.112.504.463.336l5.465-2.632a1.248 1.248 0 0 1 1.093 0l5.466 2.632c.378.182.518.056.462-.336l-.378-2.758-.084-.042-5.466-2.631a1.248 1.248 0 0 0-1.093 0l-5.465 2.631c-.028.014-.056.014-.084.028Z"
          fill="#B45032"
        />
      </g>
    </svg>
  );
};
export default SvgPlatinumStatus;
